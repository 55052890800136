@media only screen and (max-width: 620px) {
    /* For mobile phones: */
    .paper{
        width: 80%;
    }
}
.buttonText {
    color: #4285f4;
    font-weight: bolder;
}
