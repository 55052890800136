.subMenu{
    transition-delay: 10ms;
}

.btn:focus {
    outline: 0;
}

.btn-contained:active {
    filter: brightness(90%);
}