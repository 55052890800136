.coolClass{
    background-color: RED;
}

.gridCustomWidth{
    display: grid;
    grid-template-columns: auto 11rem;
    column-gap: 3rem;
    align-items: center;
    width: 100%;
}