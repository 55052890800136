input {
    padding: 3px;
}

textarea {
    padding: 3px;
}

addedTime{
    color: #FFFFFF;
}


.gridCustomWidth{
    display: grid;
    grid-template-columns: auto 7rem;
    column-gap: 2rem;
    align-items: center;
}