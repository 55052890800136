.fedd-switch {
    height: 2rem;
    min-width: 4rem;
    border-radius: 0.3rem;
    overflow: hidden;
    cursor: pointer;
    display: flex;

    .switch-button {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .switch-button-on {
        background-color: #2569e6;
        color: white;
    }

    .switch-button-on:hover {
        background-color: #114cb8;
        color: white;
    }
}
