.code {
  padding: 0.4rem;
  margin: 0;
  border-radius: 3px;
  background-color: lightgray;
  white-space: pre;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  display: block;
}

.code:hover {
  cursor: pointer;
  background-color: gray;
}